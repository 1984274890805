import { faFacebookF, faInstagram, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { Image } from '@commerce/types/common';
import { validateNetsuiteEmail } from '@components/checkout/validations';
import DownloadApp from '@components/common/DownloadApp/DownloadApp';
import RichText from '@components/common/RichText/RichText';
import Accordion from '@components/ui/Accordion/Accordion';
import Button from '@components/ui/Button/Button';
import Input from '@components/ui/Input/Input';
import Link from '@components/ui/Link';
import Text from '@components/ui/Text/Text';
import callApiRoutes from '@lib/api/call-api-routes';
import { trackFooterEvent } from '@lib/gtag';
import { pushToGTMDataLayer } from '@lib/gtm';
import { isEmeaLocale, isGBLocale, isUSLocale as isUSLocaleFn } from '@lib/locales';

import style from './Footer.module.scss';

interface LinkElements {
  id: string;
  key: string;
  value: string;
}
interface LinkListContent {
  title: string;
  listElements: LinkElements[];
  showCookiesSettingsButton: boolean;
  showDoNotSellPersonalInfoButton: boolean;
}

export interface DownloadButtons {
  image: Image;
  name: string;
  url: string;
}
interface DownloadSectionContent {
  title: string;
  buttons: DownloadButtons[];
}

export interface FooterModuleContent {
  title?: string;
  linkListContent?: LinkListContent[];
  downloadSectionContent?: DownloadSectionContent;

  footerText?: string;
}

interface Props {
  data: FooterModuleContent | null;
}

export const EmailOptInForm = ({ locale }: { locale: string }) => {
  const { t } = useTranslation('common');

  const [email, setEmail] = useState('');
  const [loading] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [inputError, setInputError] = useState<boolean>();
  const [inputSuccess, setInputSuccess] = useState<boolean>();

  const [serverError, setServerError] = useState(false);

  const handleValidation = useCallback(() => {
    // Unable to send form unless fields are valid.
    setDisabled(!validateNetsuiteEmail(email).success || email === '');
  }, [email]);

  const handleEmailOptIn = async (ev: React.SyntheticEvent<EventTarget>) => {
    ev.preventDefault();
    setDisabled(true);

    try {
      pushToGTMDataLayer({ emailSignUpEmail: email });
      const result = (
        await callApiRoutes(`/api/braze/email-opt-in?locale=${locale}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, source: 'newsletter' }),
        })
      ).data;

      if (result.message === 'success') {
        pushToGTMDataLayer({ emailSignUp: '' });
        setInputSuccess(true);

        if (inputError) {
          setInputError(false);
        }

        if (serverError) {
          setServerError(false);
        }

        setTimeout(() => {
          setInputSuccess(false);
        }, 8000);
      }
    } catch {
      setServerError(true);

      setTimeout(() => {
        setServerError(false);
      }, 8000);

      if (inputSuccess) {
        setInputSuccess(false);
      }
    } finally {
      setEmail('');
      setDisabled(!validateNetsuiteEmail(email).success || email === '');
    }

    trackFooterEvent('Email Opt-In');
  };

  useEffect(() => {
    handleValidation();
  }, [handleValidation]);

  return (
    <form className={style.emailOptInForm}>
      <div className={style.emailOptInFormContainer}>
        <div className={style.emailOptInField}>
          <Input
            type="text"
            className={style.emailOptInInput}
            title="Email"
            placeholder={t('footer.enterYourEmail')}
            value={email}
            onChange={(value: string) => setEmail(value.trim())}
          />
          <Button
            data-testid="button"
            aria-label={t('footer.signUp')}
            className={style.emailOptInButton}
            type="submit"
            onClick={handleEmailOptIn}
            loading={loading}
            disabled={disabled}
          >
            <FontAwesomeIcon
              className={style.emailOptInButtonIcon}
              icon={faArrowRight}
              title="Sign up icon"
              titleId="emailOptInButtonIcon"
              aria-hidden
            />
            <span className={style.emailOptInButtonLabel}>{t('footer.signUp')}</span>
          </Button>
        </div>

        {inputError && (
          <span data-testid="error-message" className={style.inputError}>
            {t('footer.enterValidEmail')}
          </span>
        )}

        {serverError && (
          <span data-testid="error-message" className={style.inputError}>
            {t('footer.pleaseTryAgain')}
          </span>
        )}

        {inputSuccess && (
          <span data-testid="success-message" className={style.inputSuccess}>
            {t('footer.thankYouForSignUp')}
          </span>
        )}
      </div>
    </form>
  );
};

const EmailOptInSection = ({ locale, title }: { locale: string; title?: string }) => {
  return (
    <div className={style.emailOptIn}>
      <Text
        variant="heading-2"
        className={cn(style.heading, 'text-center lg:text-right')}
        weight="bold"
        color="var(--white)"
      >
        {title}
      </Text>

      <EmailOptInForm locale={locale} />
    </div>
  );
};

const SocialMediaSection = () => {
  const { t } = useTranslation('common');

  return (
    <div className={style.socialMedia}>
      <Text
        variant="heading-2"
        className={cn(style.heading, 'text-center lg:text-right')}
        weight="bold"
        color="var(--white)"
      >
        {t('footer.connectWithUs')}
      </Text>
      <ul className={style.socialMediaLinksContainer}>
        <li className={style.socialMediaItem}>
          <a
            href="https://twitter.com/TheTileApp"
            target="_blank"
            rel="noreferrer"
            title={`Twitter ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            onClick={() => trackFooterEvent('Twitter')}
            aria-label={`Twitter ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faXTwitter}
              title={`Twitter ${t('common:screenReader.openInNewTab')}`}
              titleId="twitterFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        <li className={style.socialMediaItem}>
          <a
            href="https://www.youtube.com/channel/UCsmCffQudH3CTOm5c4KiadQ"
            target="_blank"
            rel="noreferrer"
            title={`YouTube ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            onClick={() => trackFooterEvent('YouTube')}
            aria-label={`YouTube ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faYoutube}
              title={`YouTube ${t('common:screenReader.openInNewTab')}`}
              titleId="YoutubeFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        <li className={style.socialMediaItem}>
          <a
            href="https://www.facebook.com/tile"
            target="_blank"
            rel="noreferrer"
            title={`Facebook ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            onClick={() => trackFooterEvent('Facebook')}
            aria-label={`Facebook ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faFacebookF}
              title={`Facebook ${t('common:screenReader.openInNewTab')}`}
              titleId="fbFooterIcon"
              aria-hidden
            />
          </a>
        </li>

        <li className={style.socialMediaItem}>
          <a
            href="http://instagram.com/tile"
            target="_blank"
            rel="noreferrer"
            title={`Instagram ${t('common:screenReader.openInNewTab')}`}
            className={style.socialMediaLink}
            onClick={() => trackFooterEvent('Instagram')}
            aria-label={`Instagram ${t('common:screenReader.openInNewTab')}`}
          >
            <FontAwesomeIcon
              className={style.socialMediaLinkIcon}
              icon={faInstagram}
              title={`Instagram ${t('common:screenReader.openInNewTab')}`}
              titleId="instagramFooterIcon"
              aria-hidden
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

const DownloadAppSection = ({ downloadSectionContent }: { downloadSectionContent: DownloadSectionContent }) => {
  const { t } = useTranslation('common');
  const { title, buttons } = downloadSectionContent;

  return (
    <div className={style.downloadApp}>
      <Text
        variant="heading-2"
        className={cn(style.heading, 'text-center lg:text-right')}
        weight="bold"
        color="var(--white)"
      >
        {title || t('footer.download')}
      </Text>
      <DownloadApp buttons={buttons} />
    </div>
  );
};

const CopyrightSection = () => {
  const { t } = useTranslation('common');
  const { locale = i18n.defaultLocale } = useRouter();
  return (
    <>
      <p className={cn(style.text, 'mt-m lg:mt-0')}>{t('footer.copyright', { year: `${new Date().getFullYear()}` })}</p>
      {isEmeaLocale(locale) && !isGBLocale(locale) && (
        <p className={cn(style.text, 'mt-m lg:mt-0')}>
          <br />
          {t('footer.authorizedRep.title')}
          <br />
          {t('footer.authorizedRep.address')}
          <br />
          <a href={`mailto:${t('footer.authorizedRep.email')}`} target="_blank" rel="noopener">
            {t('footer.authorizedRep.email')}
          </a>
        </p>
      )}
    </>
  );
};

const CookieTrackingPreferences = ({
  showCookies,
  showDoNotSell,
}: {
  showCookies: boolean;
  showDoNotSell: boolean;
}) => {
  const { t } = useTranslation('common');
  const cookiesRef = useRef<HTMLButtonElement>(null);

  if (!showCookies && !showDoNotSell) {
    return null;
  }

  /**
   * OneTrust modal is triggered by the button with class "ot-sdk-show-settings"
   * https://community.cookiepro.com/s/question/0D53q0000AIgPESCQ3/directly-link-to-preference-center?language=en_US
   *
   * We may have two buttons that trigger the same modal - they cannot have the same trigger property.
   *
   * This is how it is implemented in Legacy as well
   * https://github.com/search?q=org%3ATileCorporation+ot-personal-info-btn&type=code
   */
  const handleDoNotSellClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (showCookies) {
      e.preventDefault();
      cookiesRef?.current?.click();
    }
  };

  const doNotSellProps = !showCookies && {
    className: 'ot-sdk-show-settings',
  };

  return (
    <>
      {showCookies && (
        <li className="flex items-end">
          <button ref={cookiesRef} type="button" className="ot-sdk-show-settings">
            {t('footer.cookiesSettings')}
          </button>
          <img
            className={style.privacy}
            src="/p_cookie_icon.png"
            alt={t('footer.privacyIcon')}
            aria-hidden
            loading="lazy"
            width={50}
            height={25}
          />
        </li>
      )}
      {showDoNotSell && (
        <>
          <li>
            <button className="text-left" onClick={handleDoNotSellClick} type="button" {...doNotSellProps}>
              <Text variant="inline" html={t('footer.doNotSellPersonalInfo')} asElement="span" />
            </button>
          </li>
        </>
      )}
    </>
  );
};

const MenuSection = ({
  isUSLocale = true,
  linkListContent = [],
  ...props
}: {
  isUSLocale: boolean;
  linkListContent: LinkListContent[];
  className?: string;
}) => {
  const { t } = useTranslation('common');
  const isAbsolute = /^(?:[a-z]+:)?\/\//i;
  const renderLinkElements = (linkArray: Array<LinkElements>) =>
    !Array.isArray(linkArray)
      ? null
      : linkArray.map((linkElement: LinkElements) => {
          const { value: link, key: title } = linkElement;

          if (isAbsolute.test(link)) {
            return (
              <li key={title}>
                <a
                  href={link}
                  title={t('common:screenReader.openInNewTab')}
                  onClick={() => trackFooterEvent(title)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {title}
                </a>
              </li>
            );
          }

          return (
            <li key={title}>
              <Link href={link} onClick={() => trackFooterEvent(title)}>
                {title}
              </Link>
            </li>
          );
        });

  return (
    <div className={props.className}>
      <div className="block lg:hidden">
        {linkListContent?.map(({ title, showCookiesSettingsButton, showDoNotSellPersonalInfoButton, listElements }) => (
          <div key={title}>
            <hr className={style.separator} />
            <Accordion key={title} className={style.linkListAccordion} header={title}>
              <ul>
                {renderLinkElements(listElements)}
                <CookieTrackingPreferences
                  showCookies={showCookiesSettingsButton}
                  showDoNotSell={showDoNotSellPersonalInfoButton && isUSLocale}
                />
              </ul>
            </Accordion>
          </div>
        ))}
        <hr className={style.separator} />
      </div>
      <div className={style.linkListContainer}>
        {linkListContent?.map(({ title, showCookiesSettingsButton, showDoNotSellPersonalInfoButton, listElements }) => (
          <div className={style.linkList} key={title}>
            <Text className={style.linkListTitle} asElement="h2">
              {title}
            </Text>
            <ul>
              {renderLinkElements(listElements)}
              <CookieTrackingPreferences
                showCookies={showCookiesSettingsButton}
                showDoNotSell={showDoNotSellPersonalInfoButton && isUSLocale}
              />
            </ul>
          </div>
        ))}
      </div>
      <CopyrightSection />
    </div>
  );
};

const Footer: FC<Props> = ({ data }) => {
  const { locale = i18n.defaultLocale } = useRouter();
  const isUSLocale = isUSLocaleFn(locale);
  const {
    linkListContent = [],
    title = '',
    downloadSectionContent = { title: '', buttons: [] },
    footerText = '',
  } = data ?? {};

  return (
    <footer className={style.footer} id="footer">
      <div className="container px-4 lg:px-6">
        <div className={style.footerWrapper}>
          <div className={style.contactSection}>
            <EmailOptInSection locale={locale} title={title} />
            <SocialMediaSection />
            <DownloadAppSection downloadSectionContent={downloadSectionContent} />
          </div>

          <MenuSection className={style.menuSection} isUSLocale={isUSLocale} linkListContent={linkListContent ?? []} />
        </div>
        <RichText className={cn(style.text, 'mt-m lg:mt-l')} document={footerText} />
      </div>
    </footer>
  );
};

export default Footer;
